<template>
  <div class="page_">
    <div class="navbar_">
      <div class="main_">
        <img src="../../assets/medilink/newscenter/header.png" alt="" />
        <div class="text_">
          <p>{{ $t("IC[0]") || "信息中心" }}</p>
        </div>
      </div>
      <navbar :showHeader="showHeader" :nowIndex="5"></navbar>
    </div>
    <div class="block_" id="block1">
      <p class="tit_ animate">{{ $t("IC[1]") || "最新动态" }}</p>
      <div class="con_ swiper">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in datalists" :key="index">
            <img :src="item.imgSrc" alt="" />
            <div class="des_">
              <p>{{ item.tit }}</p>
              <p>{{ item.titDes }}</p>
              <p>{{ item.date }}</p>
              <a :href="item.url">{{ $t("IC[14]") || "了解详情" }} ></a>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
      <div class="con_ swiper1" id="block2">
        <swiper ref="mySwiper1" :options="swiperOptions1">
          <swiper-slide v-for="(item, index) in newsList" :key="index">
            <div class="i_left">
              <span>{{ item.month }}月</span>
              <span>{{ item.year }}</span>
            </div>
            <div class="i_right">
              <span class="drop"></span>
              <a
                target="_blank"
                :href="item1.targetUrl"
                v-for="(item1, index1) in item.conList"
                :key="index1"
              >
                <p class="a_left">
                  <span
                    ><span>{{ item1.title }}</span>
                    <span
                      class="tag_"
                      :class="{
                        top: item1.tag === '置顶',
                        new: item1.tag === 'New!'
                      }"
                      >{{ item1.tag }}</span
                    ></span
                  >
                </p>
                <span>{{ item1.date }}</span>
              </a>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="block_" id="block3">
      <p class="tit_ animate">{{ $t("FAQ[0]") || "常见问题" }}</p>
      <div class="con_">
        <div class="q_item" v-for="(itemq, index) in questionList" :key="index">
          <span></span>
          <div class="a_itemr">
            <span>{{ itemq.question }}</span>
            <span>{{ itemq.answer }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="block_" id="block4">
      <p class="tit_ animate">{{ $t("AMG[17]") || "文件下载" }}</p>
      <div class="con_ flex_">
        <div class="d_item">
          <a
            href="http://provider.medilink-global.com.cn/中间带事先授权申请表.pdf"
            download
            >{{ $t("FAQ[11]") || "中间带事先授权申请表" }}</a
          >
          <a
            href="http://provider.medilink-global.com.cn/MGU事先授权申请表.pdf"
            download
            >{{ $t("FAQ[12]") || "MGU事先授权申请表" }}</a
          >
        </div>
        <div class="d_item">
          <a
            href="http://provider.medilink-global.com.cn/中间带会员事后理赔申请单.pdf"
            download
            >{{ $t("FAQ[13]") || "中间带会员事后理赔申请单" }}</a
          >
          <a
            href="http://provider.medilink-global.com.cn/MGU会员事后理赔申请单.pdf"
            download
            >{{ $t("FAQ[14]") || "MGU会员事后理赔申请单" }}</a
          >
        </div>
        <div class="d_item">
          <!-- <a href=""></a> -->
        </div>
      </div>
    </div>
    <footerbar></footerbar>
    <sideBar v-if="showBar"></sideBar>
  </div>
</template>
<script>
import footerbar from "./components/footer.vue";
import navbar from "./components/navbar.vue";
import sideBar from "./components/sideBar.vue";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  data() {
    const CN = this.$i18n.locale == "zh-cn" ? true : false;
    return {
      showHeader: false,
      height: 0,
      swiperOptions: {
        direction: "horizontal",
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 2500,
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        pagination: {
          el: ".swiper-pagination",
          bulletElement: "span"
        }
      },
      swiperOptions1: {
        direction: "vertical",
        slidesPerView: 6
      },
      showBar: false,
      datalists: [
        {
          imgSrc: require("../../assets/medilink/newscenter/53.png"),
          tit:
            this.$t("IC[23]") + this.$t("IC[9]") || "2021年7月上新增医院名单",
          titDes: CN
            ? "本月中间带合作网络医院名单"
            : "MediLink-Global New Direct Billing Hospital List",
          date: "2021-07-15",
          url: CN
            ? "https://mp.weixin.qq.com/s/iNIGiL7vZwO_2b3LDf20DA"
            : "https://mp.weixin.qq.com/s/S6dqw6_wG7RCE87K5eDrBQ"
        },
        {
          imgSrc: require("../../assets/medilink/newscenter/52.png"),
          tit:
            this.$t("IC[22]") + this.$t("IC[9]") || "2021年6月下新增医院名单",
          titDes: CN
            ? "本月中间带合作网络医院名单"
            : "MediLink-Global New Direct Billing Hospital List",
          date: "2021-06-30",
          url: CN
            ? "https://mp.weixin.qq.com/s/eq2DyyRWvx25UwqXce3EGg"
            : "https://mp.weixin.qq.com/s/krT0Oi5PTDMkMDzIbzOnxg"
        },
        {
          imgSrc: require("../../assets/medilink/newscenter/51.png"),
          tit:
            this.$t("IC[22]") + this.$t("IC[9]") || "2021年6月上新增医院名单",
          titDes: CN
            ? "本月中间带合作网络医院名单"
            : "MediLink-Global New Direct Billing Hospital List",
          date: "2021-06-15",
          url: CN
            ? "https://mp.weixin.qq.com/s/iYFiKgTl8a2Qyj-wVqo41g"
            : "https://mp.weixin.qq.com/s/4RrKGBpwy4WsE4AJKtY0SA"
        },
        {
          imgSrc: require("../../assets/medilink/newscenter/50.png"),
          tit:
            this.$t("IC[10]") + this.$t("IC[9]") || "2021年5月下新增医院名单",
          titDes: CN
            ? "本月中间带合作网络医院名单"
            : "MediLink-Global New Direct Billing Hospital List",
          date: "2021-05-30",
          url: CN
            ? "https://mp.weixin.qq.com/s/hkBZHT_CcOonK7ZHkcMjmA"
            : "https://mp.weixin.qq.com/s/tUE8wqv8eCZHR7g8scAwsg"
        },
        {
          imgSrc: require("../../assets/medilink/newscenter/49.png"),
          tit:
            this.$t("IC[10]") + this.$t("IC[9]") || "2021年5月上新增医院名单",
          titDes: CN
            ? "本月中间带合作网络医院名单"
            : "MediLink-Global New Direct Billing Hospital List",
          date: "2021-05-15",
          url: CN
            ? "https://mp.weixin.qq.com/s/UUT1sF4_PeoReG9kQlEdDg"
            : "https://mp.weixin.qq.com/s/5TA18mFKH_71X-95SwyqDA"
        },
        {
          imgSrc: require("../../assets/medilink/newscenter/48.png"),
          tit:
            this.$t("IC[11]") + this.$t("IC[9]") || "2021年4月下新增医院名单",
          titDes: CN
            ? "本月中间带合作网络医院名单"
            : "MediLink-Global New Direct Billing Hospital List",
          date: "2021-04-30",
          url: CN
            ? "https://mp.weixin.qq.com/s/v0maSjkyWfcDZjXDPFgmyg"
            : "https://mp.weixin.qq.com/s/HNuwMgI2tD8uUk5YR4FEdA"
        }
      ],
      newsList: [
        {
          month: "07",
          year: "2021",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[23]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2021年7月上 | 中间带新增网络医疗机构",
              date: "2021-07-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/iNIGiL7vZwO_2b3LDf20DA"
                : "https://mp.weixin.qq.com/s/S6dqw6_wG7RCE87K5eDrBQ"
            }
          ]
        },
        {
          month: "06",
          year: "2021",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[22]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2021年6月下 | 中间带新增网络医疗机构",
              date: "2021-06-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/eq2DyyRWvx25UwqXce3EGg"
                : "https://mp.weixin.qq.com/s/krT0Oi5PTDMkMDzIbzOnxg"
            },
            {
              tag: "",
              title:
                this.$t("IC[22]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2021年6月上 | 中间带新增网络医疗机构",
              date: "2021-06-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/iYFiKgTl8a2Qyj-wVqo41g"
                : "https://mp.weixin.qq.com/s/4RrKGBpwy4WsE4AJKtY0SA"
            }
          ]
        },
        {
          month: "05",
          year: "2021",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[10]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2021年5月下 | 中间带新增网络医疗机构",
              date: "2021-05-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/hkBZHT_CcOonK7ZHkcMjmA"
                : "https://mp.weixin.qq.com/s/tUE8wqv8eCZHR7g8scAwsg"
            },
            {
              tag: "",
              title:
                this.$t("IC[10]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2021年5月上 | 中间带新增网络医疗机构",
              date: "2021-05-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/UUT1sF4_PeoReG9kQlEdDg"
                : "https://mp.weixin.qq.com/s/5TA18mFKH_71X-95SwyqDA"
            }
          ]
        },
        {
          month: "04",
          year: "2021",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[11]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2021年4月下 | 中间带新增网络医疗机构",
              date: "2021-04-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/v0maSjkyWfcDZjXDPFgmyg"
                : "https://mp.weixin.qq.com/s/HNuwMgI2tD8uUk5YR4FEdA"
            },
            {
              tag: "",
              title:
                this.$t("IC[11]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2021年4月上 | 中间带新增网络医疗机构",
              date: "2021-04-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/U4yiHI7MlcbRldylQqEN4A"
                : "https://mp.weixin.qq.com/s/Zvpu485xuQUmUHna7lMT-A"
            }
          ]
        },
        {
          month: "03",
          year: "2021",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[12]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2021年3月下 | 中间带新增网络医疗机构",
              date: "2020-03-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/feywNQr4l0-brdqFTnXSdQ"
                : "https://mp.weixin.qq.com/s/tauUhB_UZh5rcRegh_kNEw"
            },
            {
              tag: "",
              title:
                this.$t("IC[12]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2021年3月上 | 中间带新增网络医疗机构",
              date: "2021-03-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/GYsD6p6C1QObvol636MYGg"
                : "https://mp.weixin.qq.com/s/t1v6A6Mb5w01H97ZZvfz1g"
            }
          ]
        },
        {
          month: "02",
          year: "2021",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[13]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2021年2月下 | 中间带新增网络医疗机构",
              date: "2020-02-29",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/hXEgdd4qqiATwadJ0Mmjkg"
                : "https://mp.weixin.qq.com/s/pRzsLg9_epOkzg5GVtSbcw"
            },
            {
              tag: "",
              title:
                this.$t("IC[13]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2021年2月上 | 中间带新增网络医疗机构",
              date: "2021-02-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/7zL0_yn1rlvZ0gJGCSPF0A"
                : "https://mp.weixin.qq.com/s/jzd14QaT8Ws92KH4NB-tCg"
            }
          ]
        },
        {
          month: "01",
          year: "2020",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[21]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2021年1月下 | 中间带新增网络医疗机构",
              date: "2020-01-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/7BURxWeUReac3o_828yD8g"
                : "https://mp.weixin.qq.com/s/feqd0h2MzWdBr8OL025Zzw"
            },
            {
              tag: "",
              title:
                this.$t("IC[21]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2021年1月上 | 中间带新增网络医疗机构",
              date: "2021-01-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/qXitW-NOko7K8xmNBmxu0w"
                : "https://mp.weixin.qq.com/s/hX-0jODNnsRTrlVJNTmpVw"
            }
          ]
        },
        {
          month: "12",
          year: "2020",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[20]") ||
                "元旦假期 | 中间带部分网络医疗机构放假安排",
              date: "2020-12-31",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/0FVjm9l7lW_RpltfhCTa6g"
                : "https://mp.weixin.qq.com/s/0FVjm9l7lW_RpltfhCTa6g"
            },
            {
              tag: "",
              title:
                this.$t("IC[19]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2020年12月下 | 中间带新增网络医疗机构",
              date: "2020-12-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/-C-x1IVTymjBbKsUHymnpQ"
                : "https://mp.weixin.qq.com/s/K1SJIbl_0NrEwYOFeY-OTw"
            },
            {
              tag: "",
              title:
                this.$t("IC[19]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2020年12月上 | 中间带新增网络医疗机构",
              date: "2020-12-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/oIzSYvTGqoKEhT8inBZdYg"
                : "https://mp.weixin.qq.com/s/kAZabe2R2bn90vbgk8XyPg"
            }
          ]
        },
        {
          month: "11",
          year: "2020",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[18]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2020年11月下 | 中间带新增网络医疗机构",
              date: "2020-11-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/YrcU3Ao1VMzdjAuIdJ8xRQ"
                : "https://mp.weixin.qq.com/s/Ku6gyWS1taJBRkWDi5V29A"
            },
            {
              tag: "",
              title:
                this.$t("IC[18]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2020年11月上 | 中间带新增网络医疗机构",
              date: "2020-11-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/3R7OwmrEUjhXNQwd3mBAgA"
                : "https://mp.weixin.qq.com/s/-OGQaV5Q3reKcdJ4twj14g"
            }
          ]
        },
        {
          month: "10",
          year: "2020",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[17]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2020年10月下 | 中间带新增网络医疗机构",
              date: "2020-10-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/-C-x1IVTymjBbKsUHymnpQ"
                : "https://mp.weixin.qq.com/s/7dbdfPZlUxUJzAvFne5axg"
            },
            {
              tag: "",
              title:
                this.$t("IC[17]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2020年10月上 | 中间带新增网络医疗机构",
              date: "2020-10-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/oIzSYvTGqoKEhT8inBZdYg"
                : "https://mp.weixin.qq.com/s/XaMAF2llQKQXVD9I2PZWsw"
            }
          ]
        },
        {
          month: "09",
          year: "2020",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[5]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2020年9月下 | 中间带新增网络医疗机构",
              date: "2020-09-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/86Y-0r9Yc76AA4BvrCvPxg"
                : "https://mp.weixin.qq.com/s/WhKj0h1EA8Ratuq54DhVcQ"
            },
            {
              tag: "",
              title:
                this.$t("IC[5]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2020年9月上 | 中间带新增网络医疗机构",
              date: "2020-09-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/ApLH8_SHyXVnzf2wDe-wRw"
                : "https://mp.weixin.qq.com/s/egttCbrFuJDjZrhsMMjqOQ"
            }
          ]
        },
        {
          month: "08",
          year: "2020",
          conList: [
            {
              tag: "",
              title:
                this.$t("IC[6]") + this.$t("IC[16]") + this.$t("IC[9]") ||
                "2020年8月下 | 中间带新增网络医疗机构",
              date: "2020-08-30",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/FRRPBd51la4bKEunlwubbw"
                : "https://mp.weixin.qq.com/s/eJfRBHaH045Ir7gqRyPRDw"
            },
            {
              tag: "",
              title:
                this.$t("IC[6]") + this.$t("IC[15]") + this.$t("IC[9]") ||
                "2020年8月上 | 中间带新增网络医疗机构",
              date: "2020-08-15",
              targetUrl: CN
                ? "https://mp.weixin.qq.com/s/d8CPJ5Hp30yQRuBQg8s2oA"
                : "https://mp.weixin.qq.com/s/HqYGxkuK1VxAAJFDCNmhQg"
            }
          ]
        }
      ],
      questionList: [
        {
          question: this.$t("FAQ[1]"),
          answer:
            this.$t("FAQ[3]") ||
            "Third Party Administrators第三方管理，简称TPA。作为一种成功的金融服务业运营模式，在欧美国家被普遍的采用着。它能够弥补保险业与医疗机构的缝隙，将使保险人和被保险人同时得到利益。被保险人将得到更好的医疗、保险服务，保险人的管理成本将有所降低，医疗机构则会得到稳定的患者来源（客源）和收益。由于只需要借助TPA的力量，就能轻易的实现多赢的目标。所以，TPA虽在我国还是一个新生事物，但是已经得到保险公司、企业和个人、医疗机构的广泛认同。"
        },
        {
          question: this.$t("FAQ[4]") || "TPA的由来?",
          answer:
            this.$t("FAQ[5]") ||
            "由于团体健康保险的风险难于控制，保险的供给十分有限，价格偏高，致使一些大型企业集团趋向于采用自保的方式来建立自己的健康保险计划。这种方式虽然有利于减少中间环节、改善公司的现金流，但其缺点是不能应付一些特殊风险的发生，而且由于内部报销中的人情问题，也易导致费用的难以控制。此外，保险公司由于没有与医疗服务提供者形成利益共同体，也导致医疗费用风险难以有效控制。更重要的是，保险公司目前由于缺乏有关疾病和医疗费用的数据积累、精算基础不牢固、人才积累不充足、专业化程度不高，保险公司逐渐注重该怎么满足越来越多的客户要求更好的卫生保健，并寻找向导，更好地寻找医疗保健之路。而TPA的出现，则可在一定程度上缓解这种供需之间的不协调状况。因为TPA只承担管理的责任，而保险公司通过TPA也可积累宝贵的数据资料，为日后开发健康保险产品及制定合理的价格打好基础。客户将自己的健康保险计划交给在这方面富有经验的第三方管理公司来管理，由其选择专业性强的保险公司负责承保和承担给付的风险。TPA通过提供专业的管理服务，获得保险公司相应的管理费收入。"
        },
        {
          question: this.$t("FAQ[6]") || "第三方管理让什么人受益？",
          answer:
            this.$t("FAQ[7]") ||
            "被保险人将更方便地获得医疗保健服务和结算医院费用。在医疗机构网络里，被保险人出院时可享受免现金结算。另外，由于医疗服务使用效率的提高，被保险人需要负担的医疗保险费用将更低廉。保险公司将可提升向被保险人提供的服务标准，从而与其他保险公司相比时能显示出更大的优势。此外，在计算医疗保障成本时，他们将会有更大的信心。所有这些意味着保险公司将能够向客户提供更具竞争力的医疗保障。"
        },
        {
          question: this.$t("FAQ[8]") || "直接付费医院是什么意思？",
          answer:
            this.$t("FAQ[9]") ||
            "直接付费就是说您在我们的网络医院就诊时只需出示您的保险卡，就能得到免现金治疗，医院会直接和我们结算。您只需支付药费自付额部分和保险不涵盖的部分费用。"
        }
      ],
      loc: 0
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  mounted() {
    window.onscroll = () => {
      this.handleScroll();
    };
    let loc = this.$route.query.loc;
    if (loc != undefined && loc != null) {
      document.getElementById("block" + loc).scrollIntoView();
    }
    this.startAnimation();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.loc) {
      document.getElementById("block" + to.query.loc).scrollIntoView();
    }
  },
  components: {
    footerbar,
    navbar,
    Swiper,
    SwiperSlide,
    sideBar
  },
  directives: {
    swiper: directive
  },
  methods: {
    handleScroll(e) {
      var top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      );
      this.height = top;
      this.startAnimation();
    },
    startAnimation() {
      let elementArr = document.querySelectorAll(".animate");
      let bodyHeight = document.body.clientHeight;
      let heightArr = [];
      for (var i = 0; i < elementArr.length; i++) {
        heightArr.push(elementArr[i].offsetTop + 100);
      }
      for (var j = 0; j < heightArr.length; j++) {
        if (
          heightArr[j] >= this.height &&
          heightArr[j] < this.height + bodyHeight
        ) {
          elementArr[j].classList.add("slideUpIn60");
        }
      }
    }
  },
  watch: {
    height: {
      handler(newHeight, oldHeight) {
        if (newHeight > 400) {
          this.showBar = true;
          if (newHeight > oldHeight) {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
        } else {
          this.showHeader = false;
          this.showBar = false;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page_ {
  padding: 0;
  background-color: #fff;
  p {
    margin: 0;
  }
  .navbar_ {
    position: relative;
    width: 100%;
    .main_ {
      width: 100%;
      position: relative;
      color: #fff;
      & > img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
      .text_ {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        font-weight: bold;
        font-size: 33px;
        text-align: center;
      }
    }
  }

  .block_ {
    width: 100%;
    color: #000000;
    font-size: 11px;
    p {
      text-align: center;
    }
    .flex_ {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > .tit_ {
      font-size: 20px;
      font-weight: bold;
      padding: 50px 0;
    }
    .drop {
      width: 4px;
      height: 4px;
      background-color: #00a63c;
      display: inline-block;
      margin: 0 16px;
    }
    .bold_ {
      font-weight: bold;
    }
    .con_ {
      color: #333;
      font-size: 11px;
      &.swiper {
        width: 1000px;
        margin: 0 auto;
        position: relative;
        .swiper-pagination {
          right: 0;
          bottom: 20px;
        }
        .swiper-container {
          width: 100%;
          height: 292px;
          .swiper-slide {
            height: 292px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fbfbfb;
            img {
              width: 500px;
              flex: 1;
              height: 292px;
              object-fit: cover;
            }
            .des_ {
              width: 500px;
              flex: 1;
              display: flex;
              padding: 0 34px;
              align-items: flex-start;
              justify-content: center;
              flex-direction: column;
              p:first-child {
                font-weight: bold;
                font-size: 15px;
                padding: 10px 0;
                text-align: left;
              }
              p:nth-child(2) {
                color: #333;
                font-size: 14px;
                text-align: left;
              }
              p:nth-child(3) {
                color: #999;
                font-size: 14px;
                padding: 15px 0;
              }
              a {
                width: auto;
                padding: 0 6px;
                height: 28px;
                line-height: 28px;
                border: 1px solid rgba(0, 0, 0, 1);
                text-align: center;
                color: #000;
                font-size: 14px;
              }
            }
          }
          .swiper-pagination {
            right: 30px;
            bottom: 20px;
          }
        }
      }
      &.swiper1 {
        width: 1000px;
        margin: 35px auto;
        .swiper-container {
          min-height: 100px;
          .swiper-slide {
            display: flex;
            height: auto !important;
            justify-content: space-between;
            .i_left {
              width: 60px;
              display: flex;
              flex-direction: column;
              span {
                width: 40px;
                height: 17px;
                line-height: 17px;
                display: inline-block;
                font-size: 14px;
                color: #000;
                text-align: center;
                &:first-child {
                  background-color: rgba(0, 166, 60, 0.03);
                  border-radius: 8px;
                  font-weight: bold;
                }
                &:last-child {
                  color: #999;
                }
              }
            }
            .i_right {
              width: 940px;
              display: flex;
              flex-direction: column;
              position: relative;
              border-left: 1px solid rgba(20, 29, 51, 0.2);
              padding-left: 30px;
              padding-bottom: 20px;
              & > span {
                position: absolute;
                left: -20px;
                top: 0;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: #00a63c;
              }
              a {
                display: flex;
                flex-wrap: nowrap;
                height: 19px;
                margin-bottom: 8px;
                align-items: center;
                justify-content: space-between;
                &:hover p span {
                  color: #00a63c !important;
                }
                p {
                  width: 840px;
                  font-size: 14px;
                  text-align: left;
                  margin: 0;
                  background: url(../../assets/medilink/newscenter/newsbg.png)
                    center center no-repeat;
                  background-size: 100% 2px;
                  > span {
                    position: relative;
                    height: 15px;
                    padding: 3px 0 0;
                    background-color: #fff;
                    &::before {
                      position: absolute;
                      content: "";
                      display: block;
                      width: 3px;
                      height: 3px;
                      background-color: #cccccc;
                      border-radius: 3px;
                      top: 0;
                      left: -8px;
                      bottom: 0;
                      margin: auto 0;
                    }
                    span:first-child {
                      max-width: 660px;
                      height: 15px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      color: #000;
                    }
                    .tag_ {
                      display: inline-block;
                      min-width: 29px;
                      width: 29px;
                      height: 13px;
                      line-height: 13px;
                      &.top {
                        background-color: #00a63c;
                        color: #fff;
                      }
                      &.new {
                        border: 1px solid #00a63c;
                        color: #00a63c;
                      }
                      border-radius: 2px;
                      font-size: 8px;
                      text-align: center;
                      margin: 0 10px;
                    }
                  }
                }
                span {
                  color: #999;
                  max-width: 70px;
                  min-width: 70px;
                  text-align: right;
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
      .q_item {
        width: 1000px;
        margin: 0 auto 20px;
        display: flex;
        justify-content: space-between;
        & > span {
          width: 17px;
          height: 17px;
          background: url(../../assets/medilink/newscenter/qicon.png) center
            center no-repeat;
          background-size: 17px 17px;
          display: inline-block;
          min-width: 17px;
        }
        .a_itemr {
          width: 970px;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: #666666;
          span:first-child {
            color: #000;
            font-weight: bold;
            margin-bottom: 8px;
          }
          span:last-child {
            line-height: 20px;
          }
        }
      }
      &.flex_ {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-bottom: 47px;
        .d_item {
          flex: 1;
          display: flex;
          flex-direction: column;
          border-right: 1px solid #d5d5d5;
          font-size: 14px;
          &:nth-child(2) {
            padding: 0 50px;
          }
          &:nth-child(3) {
            padding-left: 50px;
            border-right: none;
          }
          a {
            position: relative;
            padding: 0 10px;
            color: #2160e5;
            text-decoration: underline;
            margin-bottom: 7px;
            &:last-child {
              margin-bottom: 0;
            }
            &::before {
              width: 4px;
              height: 4px;
              border-radius: 2px;
              background-color: #cccccc;
              content: "";
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              margin: auto 0;
            }
          }
        }
      }
    }
  }
}
</style>
